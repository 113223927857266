import axios from 'axios';

export const newBooking = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/booking/new`, data);

        return response.data;
    } catch (error) {

        console.log(error.message);
        return;

    }
};

export const getBookings = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/booking/all`);

        return response.data;
    } catch (error) {

        console.log(error.message);
        return;

    }
};

