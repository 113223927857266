import React, { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
// import { Breadcrumbs } from '../../../../AbstractElements';
// import ChatStatus from './ChatStatus';
import Chatting from './Chatting';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import AppLayout from '../../../../Layout/LayoutGuest';
import PageHeader from './PageHeader';

const ChatAppContain = () => {
  
  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="justify-content-center align-items-center">
          <Col sm="12" xl="6">
            {/* <Breadcrumbs mainTitle='Switch Health AI' parent='Guest' title='Chat' /> */}
            <PageHeader />
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center">
          {/* <Col className='call-chat-sidebar'>
            <Card>
              <CardBody className='chat-body'>
                <ChatStatus />
              </CardBody>
            </Card>
          </Col> */}
          <Col sm="12" xl="6" className='call-chat-body'>
            <Card>
              <CardBody className='p-0'>
                <Chatting />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer />

    </Fragment>
  );
};
export default ChatAppContain;
