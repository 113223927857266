export const MENUITEMS = [
  {
    menutitle: 'Senior Developer',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Summary',
        icon: 'home',
        active: false,
        path: `${process.env.PUBLIC_URL}/pages/dashboard`,
        type: 'link',
      
      },
      {
        title: 'Portfolio',
        icon: 'ecommerce',
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/project/list`, title: 'Projects', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/project/skills`, title: 'Skill Set', type: 'link' },
        ],
      },
      
    ],
  },
  
];
