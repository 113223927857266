import { Widgets2ChartData, Widgets2ChartData2, Widgets2ChartData2Widgets, Widgets2ChartDataWidgets } from './Chart';

export const WidgetsData = {
  title: 'Projects',
  gros: 50,
  total: 126,
  color: 'secondary',
  icon: 'cart',
};
export const WidgetsData2 = {
  title: 'Clients',
  gros: 20,
  total: 89,
  color: 'warning',
  icon: 'return-box',
};
export const WidgetsData3 = {
  title: 'Working Hours',
  gros: 70,
  total: 40,
  color: 'primary',
  icon: 'tag',
};
export const WidgetsData4 = {
  title: 'Hourly rate',
  gros: 70,
  total: 31,
  color: 'success',
  icon: 'rate',
};
export const WidgetsData1WidgetsPage = [
  {
    title: 'Purchase',
    gros: 50,
    total: 10_000,
    color: 'secondary',
    icon: 'cart',
  },
  {
    title: 'Sales return',
    gros: 20,
    total: 7000,
    color: 'warning',
    icon: 'return-box',
  },
  {
    title: 'Sales',
    gros: 70,
    total: 4_200,
    color: 'primary',
    icon: 'tag',
  },
  {
    title: 'Purchase rate',
    gros: 70,
    total: 5700,
    color: 'success',
    icon: 'rate',
  },
];

export const Widgets2Data = {
  title: 'Hours',
  total: '16,352',
  chart: Widgets2ChartData,
};
export const Widgets2Data2 = {
  title: 'Profit',
  total: '4,90k',
  chart: Widgets2ChartData2,
};
export const Widgets2DataWidgets = {
  title: 'Orders',
  total: '1,80k',
  chart: Widgets2ChartDataWidgets,
};
export const Widgets2Data2Widgets = {
  title: 'Profit',
  total: '6,90k',
  chart: Widgets2ChartData2Widgets,
};

export const LightCardData = [
  {
    title: 'Income',
    icon: 'income',
    price: '$22,678',
    gros: '+$456',
    color: 'success',
  },
  {
    title: 'Expense',
    icon: 'expense',
    price: '$12,057',
    gros: '+$256',
    color: 'danger',
  },
  {
    title: 'Cashback',
    icon: 'doller-return',
    price: '$8,475',
  },
];
export const LightCardData2 = [
  {
    title: 'Orders',
    icon: 'orders',
    price: '10,098',
    color: 'success',
  },
  {
    title: 'Earning',
    icon: 'expense',
    price: '$12,057',
    color: 'danger',
  },
  {
    title: 'Refunds',
    icon: 'doller-return',
    price: '3,001',
  },
];

export const ActivityData = [
  {
    title: 'Smart Booking',
    subTitle: '8th July, 2024',
    time: '',
    dis: 'Launched a new feature that simplifies booking processes by integrating advanced algorithms for personalized recommendations.',
    color: 'primary',
  },
  {
    title: 'Phone Marketplace',
    subTitle: '15th Oct, 2022',
    time: '',
    dis: 'Updated the marketplace platform with new functionalities allowing users to compare phone specifications and read reviews.',
    color: 'warning',
  },
  {
    title: 'MEV bot on Ethereum',
    subTitle: '20th Sep, 2022',
    time: '',
    dis: 'Deployed a new MEV (Maximal Extractable Value) bot on the Ethereum network to enhance transaction profitability and efficiency.',
    color: 'secondary',
  },
];

export const RecentSalesData = [
  {
    image: '1.jpg',
    title: 'Jane Cooper',
    subTitle: '10 minutes ago',
    badge: '$200.00',
  },
  {
    image: '2.jpg',
    title: 'Brooklyn Simmons',
    subTitle: '19 minutes ago',
    badge: '$970.00',
  },
  {
    image: '3.jpg',
    title: 'Leslie Alexander',
    subTitle: '2 hours ago',
    badge: '$300.00',
  },
  {
    image: '4.jpg',
    title: 'Travis Wright',
    subTitle: '8 hours ago',
    badge: '$450.00',
  },
  {
    image: '5.jpg',
    title: ' Mark Green',
    subTitle: '1 day ago',
    badge: '$768.00',
  },
];

export const NoteLabels = [
  {
    title: 'SAAS',
    color: 'primary',
  },
  {
    title: 'E-Commerce',
    color: 'success',
  },
  {
    title: 'Crypto',
    color: 'warning',
  },
  {
    title: 'Project',
    color: 'info',
  },
  {
    title: 'NFT',
    color: 'secondary',
  },
  {
    title: '+9',
    color: 'light',
  },
];
