import React, { Fragment } from 'react';
// import { Image, UL, LI } from '../../../../AbstractElements';
// import ChatAppContext from '../../../../_helper/Chat';
// import { AlignJustify, Headphones, Paperclip, Search, Video } from 'react-feather';
// import { Col } from 'reactstrap';
// import { useLocation } from 'react-router-dom';

const PageHeader = () => {
  // const {setMenuToggle, menuToggle } = useContext(ChatAppContext);
  // const location = useLocation();

  // const chatMenuToggle = () => {
  //   setMenuToggle(!menuToggle);
  // };
  return (
    <Fragment>
      <div className='chat-header clearfix' style={{ paddingTop: 20 }}>
        <div className='media-body'>
          <div className='about'>
            <div className='name'>
              <span className='font-primary f-24 mt-10'> SWITCH HEALTH</span>
            </div>
          </div>
        </div>
        {/* <UL attrUL={{ className: 'simple-list list-inline float-start float-sm-end chat-menu-icons d-flex flex-row' }}>
          <LI attrLI={{ className: 'list-inline-item border-0' }}>
            <a href='#javascript'>
              <Search />
            </a>
          </LI>
          <LI attrLI={{ className: 'list-inline-item border-0' }}>
            <a href='#javascript'>
              <Paperclip />
            </a>
          </LI>
          <LI attrLI={{ className: 'list-inline-item border-0' }}>
            <a href='#javascript'>
              <Headphones />
            </a>
          </LI>
          <LI attrLI={{ className: 'list-inline-item border-0' }}>
            <a href='#javascript'>
              <Video />
            </a>
          </LI>
          <LI attrLI={{ className: 'list-inline-item toogle-bar border-0' }}>
            <a href='#javascript'>
              <AlignJustify onClick={() => chatMenuToggle()} />
            </a>
          </LI>
        </UL> */}
      </div >
    </Fragment >
  );
};
export default PageHeader;
