import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { ToastContainer } from 'react-toastify';

// import { Breadcrumbs } from '../../../../AbstractElements';
// import DefaultFormLayoutClass from './DefaultFormLayout';
// import HorizontalFormLayoutClass from './HorizontalFormLayout';
// import InlineFormClass from './InlineFormClass';
// import { H6 } from '../../../../AbstractElements';
import MegaFormClass from './MegaFormClass';

const FormDefault = () => {
    return (
        <Fragment>
            {/* <Breadcrumbs parent="Switch Health" title="Booking" subParent='Sales Advsor' mainTitle='Switch Health' /> */}
            <Container fluid={true}>
                {/* <Row className="justify-content-center align-items-center">
                    <Col sm="12" xl="6">
                        <H6>SWITCH HEALTH Booking Appointment</H6>

                    </Col>
                </Row> */}
                <Row className="justify-content-center align-items-center">
                    {/* <Col sm="12" xl="6">
                        <Row>
                            <DefaultFormLayoutClass />
                            <HorizontalFormLayoutClass />
                        </Row>
                    </Col> */}
                    <Col sm="12" xl="6">
                        <Row>
                            <MegaFormClass />
                            {/* <InlineFormClass /> */}
                        </Row>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default FormDefault;