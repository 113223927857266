// //Samp lePage
import Dashboard from '../Components/Pages/Dashboard/Default';
import Projects from '../Components/Pages/Portfolio/Projects';
import Skills from '../Components/Pages/Portfolio/Skills';

// import SamplePage from '../Components/Pages/PageLayout/SimplePage';
// import FileManagerContain from '../Components/Pages/FileManager';
// import NewAdvisor from '../Components/Pages/Advisor/NewAdvisor';
// import Advisor from '../Components/Pages/Advisor/Advisor';
// import CustomCharts from '../Components/Pages/Charts/CustomCharts'

// import DraggableContain from '../Components/Pages/SalesAdvisor/Calender/DraggableCalender';
// import MailInboxContain from '../Components/Pages/SalesAdvisor/Email/MailInbox';
// import Contact from '../Components/Pages/Contact/ContactApp';
// import Applications from '../Components/Pages/Applications';
// import FormWizard from '../Components/Pages/FormWizard1/FormWizard1';
// import DailyReport from '../Components/Pages/DailyReport';

export const routes = [
  // //page
  // { path: `${process.env.PUBLIC_URL}/pages/sample-page/:layout`, Component: <SamplePage /> },
  { path: `${process.env.PUBLIC_URL}/pages/dashboard/:layout`, Component: <Dashboard /> },
  { path: `${process.env.PUBLIC_URL}/project/list/:layout`, Component: <Projects /> },
  { path: `${process.env.PUBLIC_URL}/project/skills/:layout`, Component: <Skills /> },

  // { path: `${process.env.PUBLIC_URL}/pages/report/today/:layout`, Component: <DailyReport /> },
  // { path: `${process.env.PUBLIC_URL}/pages/report/chart/:layout`, Component: <CustomCharts /> },

  // { path: `${process.env.PUBLIC_URL}/pages/advisor/advisor-list/:layout`, Component: <Advisor /> },
  // { path: `${process.env.PUBLIC_URL}/pages/advisor/new-advisor/:layout`, Component: <NewAdvisor /> },

  // // sales advisors
  // { path: `${process.env.PUBLIC_URL}/sales-advisor/calendar/draggable-calendar/:layout`, Component: <DraggableContain /> },
  // { path: `${process.env.PUBLIC_URL}/sales-advisor/email-app/:layout`, Component: <MailInboxContain /> },
  // { path: `${process.env.PUBLIC_URL}/sales-advisor/contact-app/contacts/:layout`, Component: <Contact /> },

  // // Customer
  // { path: `${process.env.PUBLIC_URL}/customer/applications/:layout`, Component: <Applications /> },
  // { path: `${process.env.PUBLIC_URL}/customer/new-appication/:layout`, Component: <FormWizard /> },
  


];
