import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

export const signup = async (user) => {
    try {
        const response = await axios.post(`${REACT_APP_API_URL}/auth/signup`, {
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            password: user.password
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new Error(`Signup failed: ${error.response.data.message}`);
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('Signup failed: No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(`Signup failed: ${error.message}`);
        }
    }
};

export const login = async (user) => {
    try {
        const response = await axios.post(`${REACT_APP_API_URL}/auth/signin`, {
            email: user.email,
            password: user.password
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new Error(`Login failed: ${error.response.data.message}`);
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('Login failed: No response from server');
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new Error(`Login failed: ${error.message}`);
        }
    }
};
