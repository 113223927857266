import React, { Fragment, useState, useEffect } from 'react';
import { Col, Card, CardBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { H6 } from '../../../../AbstractElements';
import { EmailAddress, ContactNumber, YourName } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
// import FooterCard from '../FormControl/Common/FooterCard';
import { CardFooter } from 'reactstrap'
import { Btn } from "../../../../AbstractElements";

import { newBooking } from '../../../../Services/booking.service';
import { getInviteByCode } from '../../../../Services/invite.service';

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 9; hour < 18; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            times.push(`${formattedHour}:${formattedMinute}`);
        }
    }
    return times;
};

const MegaFormClass = () => {
    const { inviteCode } = useParams();
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        phone: "",
        date: "",
        time: '09:00'
    });
    const [availableTimes, setAvailableTimes] = useState([]);
    useEffect(() => {
        const times = generateTimeOptions();
        setAvailableTimes(times);
        const fetchData = async () => {
            try {
                const res = await getInviteByCode(inviteCode);
                if (res?.invite) {
                    setFormData({
                        ...formData,
                        ...res.invite,
                        username: res.invite.fullname
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
        fetchData();
    }, []);

    const handleChange = async (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }
    const handleBook = async (e) => {
        e.preventDefault();
        console.log("booking data", formData);
        if (formData.username === "" || formData.email === "" || formData.date === '' || formData.phone === '' || formData.time === '') {
            console.log("Input correct information");
            toast.error('Input correct information');

            return;
        }
        // console.log(formData);
        try {
            const res = await newBooking(formData);
            if (res?.status === 'success') {
                toast.success('Booking successfully.');
            } else {
                toast.error("Duplicated Booking");
            }
        } catch (err) {
            console.log(err?.message);
            toast.error('Something went wrong.');
        }
    }
    return (
        <Fragment>
            <Col sm="12">
                <Card>
                    <HeaderCard title={"Switch Health Booking for Sales Advisor"} />
                    <CardBody>
                        <Form className="theme-form mega-form">
                            <H6>Booking Information</H6>
                            <FormGroup>
                                <Label className="col-form-label">{YourName}</Label>
                                <Input className="form-control" type="text" placeholder="your Name" name="username" onChange={handleChange} defaultValue={formData.username} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="col-form-label">{EmailAddress}</Label>
                                <Input className="form-control" type="email" placeholder="Enter email" name="email" onChange={handleChange} defaultValue={formData.email} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="col-form-label">{ContactNumber}</Label>
                                <Input className="form-control" type="text" placeholder="Enter contact number" name="phone" onChange={handleChange} defaultValue={formData.phone} />
                            </FormGroup>
                            <FormGroup>
                                <Label className="col-form-label">Select Date</Label>
                                <Input className="form-control" type="date" placeholder="Enter contact date" name="date" onChange={handleChange} />
                            </FormGroup>
                            {/* <FormGroup>
                                <Label for="timeInput">Select Time</Label>
                                <Input
                                    type="time"
                                    name="time"
                                    id="timeInput"
                                    placeholder="Select time"
                                    step="1800" // 30 minutes interval in seconds
                                />
                            </FormGroup> */}
                            <div>
                                <Label for="timeInput">Select Time</Label>
                                <select className='form-select form-control' size='1' name="time" onChange={handleChange}>
                                    {availableTimes.map((timeOption) => (
                                        <option key={timeOption} value={timeOption}>
                                            {timeOption}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {/* <hr className="mt-4 mb-4" />
                            <H6>{CompanyInformation}</H6>
                            <FormGroup>
                                <Label className="col-form-label">{CompanyName}</Label>
                                <Input className="form-control" type="text" placeholder="Company Name" />
                            </FormGroup>
                            <FormGroup>
                                <Label className="col-form-label">{Website}</Label>
                                <Input className="form-control" type="text" placeholder="Website" />
                            </FormGroup> */}
                            <FormGroup>
                                <Label className="col-form-label">Note</Label>
                                <Input className="form-control" type="text" placeholder="Enter note" name="note" onChange={handleChange} />
                            </FormGroup>

                        </Form>
                        {/* <hr className="mt-4 mb-4" />
                        <H6 attrH6={{ className: 'pb-2' }}>{BillingInformation}</H6>
                        <Form className="row theme-form billing-form">
                            <FormGroup className="col-auto">
                                <Input className="form-control" type="text" placeholder="Name On Card" />
                            </FormGroup>
                            <FormGroup className="col-auto">
                                <Input className="form-control" type="text" name="inputPassword" placeholder="Card Number" />
                            </FormGroup>
                            <FormGroup className="col-auto">
                                <Input className="form-control" type="text" name="inputPassword" placeholder="Zip Code" />
                            </FormGroup>
                        </Form> */}
                    </CardBody>
                    <CardFooter className="text-end">
                        <Btn attrBtn={{ color: "primary", className: "m-r-15", type: "submit", onClick: (e) => handleBook(e) }} >Submit</Btn>
                        <Btn attrBtn={{ color: "secondary", type: "submit" }} >Cancel</Btn>
                    </CardFooter>
                    {/* <FooterCard /> */}
                </Card>
            </Col>
        </Fragment>
    );
};

export default MegaFormClass;