import React, { Fragment, useState, useEffect, useContext } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H4, P } from '../../../AbstractElements';
import { EmailAddress, ForgotPassword,  Password, RememberPassword, SignIn } from '../../../Constant';

import { useNavigate } from 'react-router-dom';
// import { Jwt_token } from '../../../Config/Config';
import man from '../../../assets/images/dashboard/profile.png';
// import { handleResponse } from '../../../Services/fack.backend';

import CustomizerContext from '../../../_helper/Customizer';
import OtherWay from './OtherWay';
// import { login } from '../../../Services/auth.service';

const LoginTab = ({ selected }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const loading = useState(false)[0];
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const [value] = useState(localStorage.getItem('profileURL' || man));
  const [name] = useState(localStorage.getItem('Name'));

  useEffect(() => {
    localStorage.setItem('profileURL', man);
    localStorage.setItem('Name', 'Emay Walter');
    console.log(email, password);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    try {
      // const res = await login({ email, password });
      const res = {
        accessToken : '1231231231',
      } // for testing
      if (res) {

        localStorage.setItem('login', JSON.stringify(true));
        localStorage.setItem('accessToken', res.accessToken);
        axios.defaults.headers.common["Authorization"] = `Bearer ${res.accessToken}`;
        axios.defaults.headers.common['x-access-token'] = `${res.accessToken}`;

        toast.success('Welcome');

        history(`${process.env.PUBLIC_URL}/pages/dashboard/${layoutURL}`);

      }
    } catch (err) {
      console.log("Error", err);
      toast.error('Input correct information');
    }
    // setLoading(true);

  };

  // const loginWithJwt = (e) => {
  //   const requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: { email, password },
  //   };

  //   return fetch('/users/authenticate', requestOptions)
  //     .then(handleResponse)
  //     .then((user) => {
  //       // store user details and jwt token in local storage to keep user logged in between page refreshes
  //       setValue(man);
  //       setName('Emay Walter');
  //       localStorage.setItem('token', Jwt_token);
  //       window.location.href = `${process.env.PUBLIC_URL}/pages/sample-page/${layoutURL}`;
  //       return user;
  //     });
  // };

  return (
    <Fragment>
      <Form className='theme-form'>
        <H4>{selected === 'simpleLogin' ? 'Sign In' : 'Sign In With Jwt'}</H4>
        <P>{'Enter your email & password to login'}</P>
        <FormGroup>
          <Label className='col-form-label'>{EmailAddress}</Label>
          <Input className='form-control' type='email' required='' onChange={(e) => setEmail(e.target.value)} defaultValue={''} />
        </FormGroup>
        <FormGroup className='position-relative'>
          <Label className='col-form-label'>{Password}</Label>
          <div className='position-relative'>
            <Input className='form-control' type={togglePassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} defaultValue={''} required='' />
            <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
              <span className={togglePassword ? '' : 'show'}></span>
            </div>
          </div>
        </FormGroup>
        <div className='position-relative form-group mb-0'>
          <div className='checkbox'>
            <Input id='checkbox1' type='checkbox' />
            <Label className='text-muted' for='checkbox1'>
              {RememberPassword}
            </Label>
          </div>
          <a className='link' href='#javascript'>
            {ForgotPassword}
          </a>
          <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', disabled: loading ? loading : loading, onClick: (e) => loginAuth(e) }}>{loading ? 'LOADING...' : SignIn}</Btn>
          {/* {selected === 'simpleLogin' ? (
          ) : (
            <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2', disabled: loading ? loading : loading, onClick: (e) => loginWithJwt(e) }}>{loading ? 'LOADING...' : LoginWithJWT}</Btn>
          )} */}
        </div>
        <OtherWay />
      </Form>
      <ToastContainer />

    </Fragment>
  );
};

export default LoginTab;
