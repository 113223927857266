import axios from 'axios';

export const sendChatText = async (data) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/smart-chat/send`, data);

        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // throw new Error(`Signup failed: ${error.response.data.message}`);
            return;
        } else if (error.request) {
            // The request was made but no response was received
            // throw new Error('Signup failed: No response from server');
            return;

        } else {
            // Something happened in setting up the request that triggered an Error
            // throw new Error(`Signup failed: ${error.message}`);
            return;

        }
    }
};

