import SmartChat from '../Components/Pages/GuestPages/SmartChat';
// import BookingWizard from '../Components/Pages/GuestPages/BookingWizard/SmartBooking'
import BookingDefault from '../Components/Pages/GuestPages/BookingDefault'

export const guestRoutes = [
  
  { path: `${process.env.PUBLIC_URL}/guest/smart-chat/:inviteCode`, Component: <SmartChat /> },
  { path: `${process.env.PUBLIC_URL}/guest/booking-wizard/:inviteCode`, Component: <BookingDefault />  },

];
